/* zoho HR style_sheet */

/* ----------Sign In------------ */

/* .row {
  /* margin-right: 0px !important;
  margin-left: 0px !important;
} */

body{
  background-color: hsl(220, 20%, 21%);
}

.signin {
  
  
  width: 100%;
}

.formbox {
  width: 60rem;
  background-color: #405566;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.28), 0 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  margin-top: 8rem;
 
}

form {
  padding-top: 3rem;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  
}
.form {
  padding: 2rem;
}

.imgDiv{
  background-color: #ffff;
  border-radius: 5px;
  height: 350px;
  width: 110%;
}
.form_image {
  padding: 3rem;
  text-align: center;
  margin-top: 3.7rem;
  margin-right: 2rem;
  
}

.form_image img {

  height: 325px;
  width: 100%;
  object-fit: contain;
  
}

.form h1 {
  text-align: center;
  padding-bottom: 3rem;
  color: white;
}

.form a {
  text-align: center;
  text-decoration: none;
  color: #91998e;
  font-size: 1.5rem;
}

.form label {
  margin-left: 1rem;
  color: #91998e;
  font-size: large;
}

.form input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  border-radius: 8px;
  border: none;
}

.form input:focus {
  outline: none;
}

.button {
  text-align: center;
}

.button button {
  padding: 0.5rem 2rem 0.5rem 2rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #c53a48;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.28), 0 10px 10px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
}

@media screen and (max-width: 500px) {
  .form_image {
    display: none;
  }
}

element.style {
  z-index: 101;
  transition-duration: 500ms;
  top: 0px;
  left: 110px;
  transform: translate3d(-100%, 0px, 0px);
  width: 250px;
  height: 100vh;
}

/* -----------Navbar------------ */

* {
  margin: 0px;
  padding: 0px;
}

.gg{
  border:none;
  background:none;
}

.navcont {
  background-color: whitesmoke;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}

.navbar-toggler-icon {
  color: #405566;
}

.navbar-brand {
  color: 	#c53a48;
  margin-left: 1rem;
}

.fa-bell {
  color: #c53a48;
  padding: 0.5rem;
}

.fa-circle-plus {
  color: #c53a48;
  padding: 0.5rem;
}

.profile {
  width: 2rem;
  height: 2rem;
  text-align: end;
}

.profile a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.left-div {
  float: left;
}
.right-div {
  float: right;
}
.navbar-nav {
  justify-content: right;
  display: flex;
  align-items: center;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


.navbar-toggler:focus {
  box-shadow: none;
  border: 1px solid #ebebeb;
  padding: 1px 2px;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

/*----------- Sidebar----------- */

.sidebar {
  position: fixed;
  height: 100%;
  background-color: #405566;
}

.sidelook {
  position: relative;
  bottom: -4rem;
}

.logo {
  padding: 1rem 1rem 0.5rem 1rem;
  font-size: larger;
  color: #c53a48;
}

.navlist {
  margin: 1rem;
}

.item {
  list-style: none;
  padding: 8px;
  margin-top:2rem;
}

.item li {
  padding-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.item li a {
  text-decoration: none;
  color: white;
  text-align: center;
  font-size: 14px;
}

.item li i {
  color: white;
  font-size: 14px;
} 
.row{
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
}
.row1{
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
}




/* ------->user-Leavepage-------->  */

.leavecrd:hover {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
} 

.horizen {
  margin: 1.5rem 0 1.5rem 0;
}

.card-bdy h5 {
  color: #405566;
  text-align: center;
  padding: 1rem 0 1rem 0;
}

.card-bdy i {
  color: #c53a48;
  font-size: 2rem;
  
}

.card-bdy p {
  color: #91998e;
  text-align: center;
}
.holidays {
  height: 19.5rem;
  margin: 2.5rem 0 0 1rem;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
}

.dropdown button {
  padding: 1rem;
}

.dropdown button:focus {
  outline: none;
}

.leacebtn {
  background-color: #c53a48;
  color: white;
  font-size: 1rem;
  border: none;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  border-radius: 0.5rem;
}

.levbtn {
  text-align: center;
  margin: 0 0 1rem 0;
}

.levbtn button {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #cc4b58;
  color: white;
  align-items: end;
}

/* ---------->user- Leaveform ---------> */

.contant {
  margin: 2rem 0 3.5rem 7rem;
}

.leave-from {
  width: 65rem;
  height: auto;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem 0 0 8.5rem;
}

.leave-from h3 {
  color: #c53a48;
  text-align: left;
  padding: 0.5rem 0 0 4rem;
}

hr {
  color: #405566;
}

label {
  color: #405566;
}

textarea {
  width: 100%;
  height: 6rem;
  border-color: #91998e;
  border-radius: 1rem;
  background-color: #f3f8f1;
  border: none;
}

textarea:focus {
  outline: none;
}

.butn {
  text-align: center;
  padding: 0 0 1rem 0;
}

.butn button {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  background-color: #c53a48;
  color: white;
  border-radius: 0.5rem;
  margin: 0 0 1rem 1rem;
}

.fominp {
  border-radius: 1rem;
  background-color: #f3f8f1;
  border: none;
}

body{
  width:99%;
}

/* ---------Birthcrd --------- */

.birth {
  width: 18rem;
  margin: 3rem 0 3rem 3rem;
  display: inline-block;
}

.birth:hover {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
}

.card-body h5 {
  text-align: center;
  margin-bottom: 1rem;
  color: #c53a48;
}

.card_image {
  width: 2.5rem;
  height: 2.5rem;
  text-align: end;
}

.card_image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.birrow {
  padding-left: 1rem;
}

.text p {
  color: #91998e;
  line-height: 12px;
}

.btn a button {
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  border: none;
  background-color: #405566;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: white;
}

/* birthcrd modal */

.sendmsg {
  padding: 0.3rem 1rem 0.3rem 1rem;
  border: none;
  background-color: #c53a48;
  border-radius: 0.5rem;
  color: white;
  margin: 0 0 1rem 5.8rem;
  text-align: center;
  font-size: 0.8rem;
}

.btn button {
  padding: 0.3rem 1rem 0.3rem 1rem;
  border: none;
  background-color: #c53a48;
  border-radius: 0.5rem;
  color: white;
  margin: 0 0 1rem 0;
}

.wish {
  color: #c53a48;
}

/* leavereq */
.acc-btn{
  background-color:#405566;
  color:white;
  font-size:0.8rem;
}

.rej-btn{
  background-color:#c53a48;
  color:white;
  font-size:0.8rem;
}

/* Birthday-crd carousel */
.preview {
  margin: 0rem;
}

/* --------> Employes -----------> */

.addemp {
  text-align: end;
}

.addemp button {
  margin: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: none;
  color: #9ea59c;
  align-items: end;
}

.viewbtn {
  background-color:#c53a48;
  color: white;
  font-size: 0.8rem;
  border: none;
  border-radius: 0.3rem;
  padding:0.5rem 0.8rem 0.5rem 0.8rem;
}

.viewbtn:hover{
  color:white !important;
}
.add-emp-tit h5{
  color:#405566;
}
/* .app.js */

.Sideall {
  margin-top: 3rem;
}

.alldata {
  margin: 4rem;
}

/* Empdetails */

.empdetl {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.detl {
  align-items: center;
  display: flex;
}

.texx {
  margin: 2rem;
  color: #c53a48;
}

.empimg {
  width: 6rem;
  height: 6rem;
}

.emp_del_pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.pelrde h5 {
  text-align: center;
  padding-bottom: 1rem;
  color: #405566;
}

.jobdel h5 {
  text-align: center;
  padding-bottom: 1rem 0 2rem 0;
  color: #405566;
}

#elem {
  text-align: center;
}

.detail {
  padding-left: 10.5rem;
  text-align: left;
}

.detail p {
  color: #91998e;
}

/* ------> Department/model ---------> */
/* add department model */
.dephead {
  color: #c53a48;
  text-align: center;
}

.dephead h5 {
  text-align: center;
}

.mdldep {
  width: 32rem;
  height: 25rem;
  padding: 1rem;
  margin-top: 5rem;
}
.fomstl {
  margin-top: 0 !important;
}

.fomstl label {
  color: #9ea59c;
}

.fomstl input {
  margin: 0 0 1.5rem 0;
  padding: 0.3rem;
  border-radius: 0.6rem;
  background-color: #f3f8f1;
  border: none;
}

.mdlbdy {
  margin: 1rem 1rem 1.5rem 1rem !important;
}

form input:focus {
  border: none !important;
}

.depbut {
  background-color: #c53a48;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  margin: 1rem;
  font-size: 0.8rem;
}

.formbtn {
  text-align: center;
  margin-top: 1.5rem;
}

.formbtn button {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #cc4b58;
  color: white;
  align-items: end;
}

/* Edit department model */
.dep-edt-head{
  color: #c53a48;
  text-align: center;
}

.dep-edt-head h5 {
  text-align: center;
}

.mdl-edt-dep {
  width: 32rem;
  height: 25rem;
  padding: 1rem;
  margin-top: 5rem;
}
.fom-edt-stl {
  margin-top: 0 !important;
}

.fom-edt-stl label {
  color: #9ea59c;
}

.fom-edt-stl input {
  margin: 0 0 1.5rem 0;
  padding: 0.3rem;
  border-radius: 0.6rem;
  background-color: #f3f8f1;
  border: none;
}

.mdl-edt-bdy {
  margin: 1rem 1rem 1.5rem 1rem !important;
}

form input:focus {
  border: none !important;
}

.depbut {
  background-color: #c53a48;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  margin: 1rem;
  font-size: 0.8rem;
}

.form-edt-btn {
  text-align: center;
  margin-top: 1.5rem;
}

.form-edt-btn button {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #cc4b58;
  color: white;
  align-items: end;
}



/* employes-model */

.mdlnewemp {
  max-width: 52rem !important;
  height: 52rem;
}

.newempbtn {
  background-color: #c53a48;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.3rem;
  color: white;
  margin: 1rem;
  font-size: 0.8rem;
}

.newempfom {
  margin-top: 0 !important;
}

.perdel {
  text-align: center;
  color: #405566;
}

.edudel {
  text-align: center;
  color: #405566;
}

.jobdel {
  text-align: center;
  color: #405566;
}

.empcol {
  padding: 1rem;
}

.empcol input:focus {
  outline: none;
}

.empcol label {
  color: #9ea59c;
}

.empcol input {
  margin: 0.5rem;
  padding: 0.3rem 2rem 0.3rem 2rem;
  border-radius: 0.6rem;
  background-color: #f3f8f1;
  border: none;
}

.empbtn {
  text-align: center;
}

.empbtn button {
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #cc4b58;
  color: white;
  align-items: end;
}

.empmod {
  color: #c53a48;
}

.emphed {
  color: #c53a48;
}

/* Newhires */
.newhireimg {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0 1rem 6.5rem;
}

.newhireimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.newhire {
  width: 18rem;
  margin: 3rem 0 1rem 3rem;
}

.newhire:hover {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
}

.txtt {
  text-align: center;
  color: #91998e;
}

/* Leavereq */
.fotbtn button {
  background-color: #c53a48;
  color: white;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  text-align: left;
  border-radius: 0.5rem;
}

textarea {
  height: 15rem;
  padding: 1rem;
}

.leave-hed h5{
  color:#405566;
}

/* Attendns */
.attbtn {
  background-color: #405566;
  border: none;
  color: white;
}

.fa-pencil {
  color: white;
  font-size: 0.8rem;
}

.savchg {
  background-color: #c53a48;
  color: white;
}

.attsavchg {
  text-align: center;
  margin: 0 0 1rem 0;
}

.attsavchg button {
  background-color: #c53a48;
  color: white;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  text-align: left;
  border-radius: 0.5rem;
}

/* Atendns filter */
.att-fil-btn button {
  background-color: #c53a48;
  color: white;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  text-align: left;
  border-radius: 0.5rem;
  margin: 0.5rem 0 1.5rem 0;
  font-size:0.8rem;
}

.check {
  border: none;
  background-color: #f3f8f1;
  border-radius: 0.5rem;
  margin: 0 1rem 0 0;
}

.chklab {
  color: #9ea59c;
}

.inp-dep{
  margin-bottom:-1rem;
}


/* Absentees model */
.res-rej{
  font-size:0.8rem;
  background-color:#405566;
  border:none;
  color:white;
}


/* showfilter/hidefilter */

.filtercls {
  background-color: #c53a48;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: white;
  border-radius: 0.3rem;
  margin: 1rem 0 1rem 0;
  font-size:0.8rem;
}

form {
  margin-top: 0rem !important;
}

.nmg {
  margin-bottom: 1rem;
}


.nmg input::placeholder {
  color: #ecf0eb;
}


.wkhis {
  color: #d7dad6;
}
.datehis {
  margin: 0 0 1rem 0;
}

.both-btn{
  text-align: end;
}

/* Update-page */

.updatecrd {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.1);
  border:none;
} 

.updatecrd{
  margin-top:1rem;
  border:none;
}

.updatehrz {
  margin: 1.5rem 0 1.5rem 0;
}

.updt-bdy p{
color:#c53a48;
font-size:large;
margin:1rem 0 1rem 0;
}

.updt-bdy h4{
  color:#c53a48;
  text-align: center;
  padding:0 0 1rem 0;
  }

  .updt-cont{
    margin-top:3rem;

  }

  .link-update{
    text-decoration:None;
  }


